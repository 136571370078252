import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=c3a3a0b8&scoped=true&"
import script from "./Table.vue?vue&type=script&lang=ts&"
export * from "./Table.vue?vue&type=script&lang=ts&"
import style0 from "./Table.vue?vue&type=style&index=0&id=c3a3a0b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3a3a0b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VDataTable,VIcon})
