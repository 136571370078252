























































































import {
  ListPageFilters,
  PatientStatusFilter,
  MultiSelect,
  ColorsCheckbox,
  InlineLoader,
} from '@/shared';
import sink from '@/sink';
import DatePicker from '@/shared/components/DatePicker.vue';
import { defineComponent, computed } from '@vue/composition-api';
import {
  EscalationsFilterChanged,
  EscalationsFiltersCleared,
  EscalationsFilterSearched,
  EscalationsFiltersRestored,
} from './+state/events';
import { EscalationsFilters } from './models';

export default defineComponent({
  components: {
    DatePicker,
    InlineLoader,
    ListPageFilters,
    PatientStatusFilter,
    MultiSelect,
    ColorsCheckbox,
  },
  setup() {
    const filters = sink.select('escalations.filters');
    sink.broadcast(EscalationsFiltersRestored());

    const overrideFormValidity = computed(
      () => !filters.value.programIds?.length,
    );

    return {
      overrideFormValidity,
      loadingPrograms: sink.select('programs.loading'),
      loadingProviders: sink.select('providers.loading'),
      loadingChats: sink.select('escalations.loading'),
      filters,
      programs: sink.select('programs.select-options.clinician'),
      providers: sink.select('providers.select-options'),
      isDateRangeValid: computed(() => {
        if (
          filters.value.enrollmentDateFrom &&
          filters.value.enrollmentDateTo
        ) {
          return (
            new Date(filters.value.enrollmentDateTo) >=
              new Date(filters.value.enrollmentDateFrom) ||
            'Chat Date From must be on/before Chat Date To'
          );
        }
        return true;
      }),
      onSearch: sink.lazyBroadcast(
        EscalationsFilterSearched({ origin: 'search', resetPage: true }),
      ),
      onClearAll: sink.lazyBroadcast(EscalationsFiltersCleared()),
      onInputChanged(input: keyof EscalationsFilters, value) {
        sink.broadcast(EscalationsFilterChanged({ input, value }));
      },
    };
  },
});
