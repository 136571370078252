var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"escalations-filters"},[_c('ListPageFilters',{attrs:{"loading":_vm.loadingChats,"overrideFormValidity":_vm.overrideFormValidity},on:{"search":_vm.onSearch,"clear-all":_vm.onClearAll}},[_c('DatePicker',{attrs:{"isoDate":_vm.filters.enrollmentDateFrom,"maxISODate":_vm.filters.enrollmentDateTo,"additionalRules":[function () { return _vm.isDateRangeValid; }],"name":"chat-date-from","id":"escalations-filter-chat-date-from","label":"Chat Date From"},on:{"change":function($event){return _vm.onInputChanged('enrollmentDateFrom', $event)}}}),_c('DatePicker',{attrs:{"isoDate":_vm.filters.enrollmentDateTo,"minISODate":_vm.filters.enrollmentDateFrom,"additionalRules":[function () { return _vm.isDateRangeValid; }],"name":"chat-date-to","id":"escalations-filter-chat-date-to","label":"Chat Date To"},on:{"change":function($event){return _vm.onInputChanged('enrollmentDateTo', $event)}}}),_c('InlineLoader',{attrs:{"loading":_vm.loadingPrograms,"content":"Programs"}},[_c('MultiSelect',{staticClass:"mt-4",attrs:{"options":_vm.programs,"inputValue":_vm.filters.programIds,"label":"Programs (Required)","id":"escalations-filter-program-ids","name":"program-ids"},on:{"change":function($event){return _vm.onInputChanged('programIds', $event)}}})],1),_c('InlineLoader',{attrs:{"loading":_vm.loadingProviders,"content":"Providers"}},[_c('MultiSelect',{staticClass:"mt-4",attrs:{"options":_vm.providers,"inputValue":_vm.filters.providerIds,"label":"Provider","id":"escalations-filter-provider-ids","name":"provider-ids"},on:{"change":function($event){return _vm.onInputChanged('providerIds', $event)}}})],1),_c('PatientStatusFilter',{attrs:{"status":_vm.filters.patientStatus,"id":"escalations-filter-patient-status"},on:{"change":function($event){return _vm.onInputChanged('patientStatus', $event)}}}),_c('ColorsCheckbox',{attrs:{"options":[
        {
          value: 'red',
          content: 'Red',
        },
        {
          value: 'yellow',
          content: 'Yellow',
        },
        {
          value: 'green',
          content: 'Green',
        },
        {
          value: 'none',
          content: 'None',
        } ],"inputValue":_vm.filters.chatStatus,"label":"Chat Status","id":"patients-filter-chat-status","name":"chat-status"},on:{"change":function($event){return _vm.onInputChanged('chatStatus', $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }