var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"expandable-table",attrs:{"show-expand":"","must-sort":"","mobile-breakpoint":"1080","item-key":"checkupId","no-data-text":_vm.tableMessage,"headers":_vm.headers,"items":_vm.escalations,"page":_vm.pageNumber,"sort-by":_vm.sortCol,"sort-desc":_vm.sortDir === 'desc',"server-items-length":_vm.count,"items-per-page":25,"hide-default-footer":!_vm.escalations.length,"expanded":_vm.expandedRows,"footer-props":{
    itemsPerPageOptions: [25],
    showCurrentPage: true,
    showFirstLastPage: true,
  }},on:{"update:page":_vm.updatePage,"update:sort-desc":_vm.onChangeSortDirection,"update:sort-by":_vm.onChangeSortColumn,"item-expanded":_vm.onItemExpanded},scopedSlots:_vm._u([{key:"item.chatDate",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"date-column"},[_c('span',[_vm._v(_vm._s(item.chatDate))]),_c('router-link',{staticClass:"date-column__name",attrs:{"to":("/patients/" + (item.patientId))}},[_vm._v(_vm._s(item.patientName))])],1)]}},{key:"item.patientName",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"patient-column"},[_c('router-link',{staticClass:"patient-column__name",attrs:{"to":("/patients/" + (item.patientId))}},[_vm._v(_vm._s(item.patientName))]),_c('div',{staticClass:"patient-column__id"},[_c('p',{staticClass:"patient-column__id-header"},[_vm._v(" ID ")]),_c('p',[_vm._v(" "+_vm._s(item.clinicId)+" ")])])],1)]}},{key:"item.chatStatus",fn:function(ref){
  var item = ref.item;
return [_c('StatusIndicatorIcon',{attrs:{"status":item.chatStatus}})]}},{key:"expanded-item",fn:function(ref){
  var headers = ref.headers;
  var item = ref.item;
return [_c('td',{staticClass:"chats-sub-table",attrs:{"colspan":headers.length}},[_c('InlineLoader',{staticClass:"inline-loader",attrs:{"loading":item.responsesLoading,"content":"Fetching Responses..."}},[_c('v-data-table',{staticClass:"show-headers",attrs:{"hide-default-footer":"","no-data-text":"Responses are still being processed and will be available shortly.","headers":_vm.subTableHeaders,"items":item.responses,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.responseStatus",fn:function(ref){
  var item = ref.item;
return [_c('StatusIndicatorIcon',{attrs:{"status":item.responseStatus}})]}},{key:"item.linkIcon",fn:function(ref){
  var item = ref.item;
return [_c('router-link',{attrs:{"to":"/responses"}},[_c('i',{staticClass:"material-icons-outlined icon",attrs:{"title":"View all responses to this question"},on:{"click":function($event){return _vm.viewAllResponses(item)}}},[_vm._v(" message ")])])]}}],null,true)})],1)],1)]}},{key:"item.data-table-expand",fn:function(ref){
  var expand = ref.expand;
  var isExpanded = ref.isExpanded;
return [_c('p',{staticClass:"action-column",on:{"click":function($event){return expand(!isExpanded)}}},[_c('span',{staticClass:"action-column__text"},[_vm._v(" "+_vm._s(isExpanded ? 'Show less' : 'Show more')+" ")]),(isExpanded)?_c('v-icon',[_vm._v("expand_less")]):_c('v-icon',[_vm._v("expand_more")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }