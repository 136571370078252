













import { ListPageLayout, InlineLoader } from '@/shared';
import { defineComponent } from '@vue/composition-api';
import Table from './Table.vue';
import Filters from './Filters.vue';
import sink from '@/sink';

export default defineComponent({
  components: { ListPageLayout, Table, Filters, InlineLoader },
  setup() {
    return {
      loading: sink.select('escalations.loading'),
    };
  },
});
